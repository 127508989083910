import React from "react";
import { useRef } from "react";
import emailjs from "emailjs-com";
function ContactThree(props) {
  const nameInput = useRef();
  const emailInput = useRef();
  const subjectInput = useRef();
  const messageInput = useRef();
  const numberInput = useRef();

  // const templateId = "service_personalni";
  // const serviceID = "template_mq53fhn";
  function sendEmail(e) {
    e.preventDefault();
    const enteredName = nameInput.current.value;
    const enteredNumber = numberInput.current.value;
    const enteredEmail = emailInput.current.value;
    const enteredSubject = subjectInput.current.value;
    const enteredMessage = messageInput.current.value;

    emailjs
      .sendForm(
        "service_personalni",
        "template_mq53fhn",
        {
          name: enteredName,
          message: enteredMessage,
          number: enteredNumber,
          reply_to: enteredSubject,
          email: enteredEmail,
        },
        e.target,
        "user_okJQC4Ix2ffCEfYcXM00A"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
    console.log("object");
  }

  return (
    <div className="contact-form--1">
      <div className="container">
        <div className="row row--35 align-items-start">
          <div className="col-lg-6 order-2 order-lg-1">
            <div className="section-title text-left mb--50">
              <h2 className="title">{this.props.contactTitle}</h2>
              <p className="description">
                I am available for freelance work. Connect with me via phone:{" "}
                <a href="tel:+8801923088574">01923 088574</a> or email:
                <a href="mailto:admin@example.com"> admin@example.com</a>{" "}
              </p>
            </div>
            <div className="form-wrapper">
              <form onSubmit={sendEmail}>
                <label htmlFor="item01">
                  <input
                    type="text"
                    name="name"
                    id="item01"
                    value={this.state.rnName}
                    ref={nameInput}
                    placeholder="Your Nameee *"
                  />
                </label>
                <label htmlFor="item05">
                  <input
                    type="number"
                    name="number"
                    id="item05"
                    value={this.state.rnNumber}
                    ref={numberInput}
                    placeholder="Your number *"
                  />
                </label>

                <label htmlFor="item02">
                  <input
                    type="text"
                    name="email"
                    id="item02"
                    value={this.state.rnEmail}
                    ref={emailInput}
                    placeholder="Your email *"
                  />
                </label>

                <label htmlFor="item03">
                  <input
                    type="text"
                    name="subject"
                    id="item03"
                    value={this.state.rnSubject}
                    ref={subjectInput}
                    placeholder="Write a Subject"
                  />
                </label>
                <label htmlFor="item04">
                  <textarea
                    type="text"
                    id="item04"
                    name="message"
                    value={this.state.rnMessage}
                    ref={messageInput}
                    placeholder="Your Message"
                  />
                </label>
                <button
                  className="rn-button-style--2 btn-solid"
                  type="submit"
                  value="submit"
                  name="submit"
                  id="mc-embedded-subscribe"
                >
                  Submit
                </button>
              </form>
            </div>
          </div>
          <div className="col-lg-6 order-1 order-lg-2">
            <div className="thumbnail mb_md--30 mb_sm--30">
              <img src={`${props.contactImages}`} alt="trydo" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactThree;
