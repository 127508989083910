import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import CounterOne from "../elements/counters/CounterOne";
import Testimonial from "../elements/Testimonial";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/HeaderThree";
import Footer from "../component/footer/Footer";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

class About extends Component {
  render() {
    let title = "United Fitness Team",
      description =
        "Naš tim se ne bavi samo konsultacijama i pripremama planova treninga, već u potpunosti posvećujemo vreme klijentu. U prostoru vašeg doma ili objekta koji Vama odgovara u unapred zakazano vreme, video pozivom preko Viber, Whats App i Zoom aplikacije sprovodimo personalne treninge 1 na 1 ( do 2 klijenta istovremeno ), gde su treninzi bazirani prema Vašim potrebama i ciljevima, uz konstantnu komunikaciju i korigovanje tokom treninga.";
    return (
      <React.Fragment>
        <PageHelmet pageTitle="O Nama" />
        <Helmet>
          <meta
            name="description"
            key="description"
            content="Naš tim se ne bavi samo konsultacijama i pripremama planova treninga, već u potpunosti posvećujemo vreme klijentu. U prostoru vašeg doma ili objekta koji Vama odgovara u unapred zakazano vreme, video pozivom preko Viber, Whats App i Zoom aplikacije sprovodimo personalne treninge 1 na 1 ( do 2 klijenta istovremeno ), gde su treninzi bazirani prema Vašim potrebama i ciljevima, uz konstantnu komunikaciju i korigovanje tokom treninga."
          />
          <meta
            property="og:title"
            content="O nama || United Fitness || Personalni Trener"
            key="og:title"
          />
          <meta
            property="og:url"
            key="og:url"
            content="https://www.united-fitness-personalni.com/about"
          />
          <link
            rel="canonical"
            key="canonical"
            href="https://www.united-fitness-personalni.com/about"
          />
        </Helmet>
        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
        />
        {/* Start Breadcrump Area */}
        <Breadcrumb title={"O nama"} />
        {/* End Breadcrump Area */}

        {/* Start About Area  */}
        <div className="rn-about-area ptb--120 bg_color--1">
          <div className="rn-about-wrapper">
            <div className="container">
              <div className="row row--35 align-items-center">
                <div className="col-lg-5">
                  <div className="thumbnail">
                    <div className="column">
                      <img
                        className="w-100"
                        src="/assets/slike/naslovna2.jpg"
                        alt="United Fitness"
                      />

                      <img
                        className="w-100"
                        src="/assets/slike/naslovna.jpg"
                        alt="United Fitness"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-7">
                  <div className="about-inner inner">
                    <div className="section-title">
                      <h2 className="title">{title}</h2>
                      <p className="description">{description}</p>
                    </div>
                    <div className="row mt--30 mt_sm--10">
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                        <div className="about-us-list">
                          <h3 className="title">O timu</h3>
                          <p>
                            United Fitness Team čine diplomirani profesor
                            fizičkog vaspitanja Vladan Mirić i strukovni trener
                            u rekreaciji Marko Obradović. Treneri sa
                            višegodišnjim iskustvom u poljima fitnesa,
                            takmičarskih disciplina, grupnih treninga, kao i
                            nutricionizma. Već dugi niz godina radimo sa
                            rekreativcima, sportistima i svima onima koji žele
                            da se osećaju zadovoljno, sposobno i zdravo u svome
                            telu. Naš zadatak je da našu saradnju učinimo
                            zanimljivom, lakšom, a sve u cilju postizanja
                            konačnog rezultata i Vaše željene forme u udobnosti
                            Vašeg doma ili prostora koji Vama odgovara.
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                        <div className="about-us-list">
                          <h2 className="title">Šta nas čini posebnim</h2>
                          <p>
                            <strong>Jedini smo u Srbiji</strong> koji uz
                            odabrani paket online video treninga UŽIVO, nudimo
                            mogućnost treniranja i u fitnes centru, kao i
                            dolazak na kućnu adresu ( sa istezanjem i merenjem
                            na digitalnoj medicinskoj vagi ).
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End About Area  */}

        {/* Start CounterUp Area */}
        <div className="rn-counterup-area pb--120 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title text-center">
                  <h3 className="fontWeight500">Fun Facts</h3>
                </div>
              </div>
            </div>
            <CounterOne />
          </div>
        </div>
        {/* End CounterUp Area */}

        {/* Start Team Area  */}
        <div className="rn-team-area bg_color--1 pb--120">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title service-style--3 text-center mb--25">
                  <h2 className="title">Naš Tim</h2>
                </div>
              </div>
            </div>
            <div className="row">
              {/* Start Single Team  */}
              <div className=" col-md-6 col-sm-6 col-12">
                <div className="team">
                  <div className="thumbnail">
                    <img
                      className="w-100"
                      src="/assets/images/team/trener1.png"
                      alt="Blog Images"
                    />
                  </div>
                  <div className="content">
                    <h4 className="title">Vladan</h4>
                    <p className="designation">
                      Diplomirani Master profesor fizičkog vaspitanja
                    </p>
                    <button type="button" className="btn btn-success mt--10">
                      <Link to="/aboutDetails/trener1">O Treneru</Link>
                    </button>
                  </div>
                  {/* <ul className="social-icon">
                    <li>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.facebook.com/"
                      >
                        <FaFacebookF />
                      </a>
                    </li>

                    <li>
                      <a href="https://twitter.com/">
                        <FaTwitter />
                      </a>
                    </li>
                  </ul> */}
                </div>
              </div>
              {/* End Single Team  */}

              {/* Start Single Team  */}
              <div className="col-md-6 col-sm-6 col-12">
                <div className="team">
                  <div className="thumbnail">
                    <Link to="/">
                      <img
                        className="w-100"
                        src="/assets/images/team/trener2.png"
                        alt="Blog Images"
                      />
                    </Link>
                  </div>
                  <div className="content">
                    <h4 className="title">Marko</h4>
                    <p className="designation">Strukovni trener u rekreaciji</p>
                    <button type="button" className="btn btn-success mt--10">
                      <Link to="/aboutDetails/trener2">O Treneru</Link>
                    </button>
                  </div>
                  {/*
                  <ul className="social-icon">
                    <li>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.facebook.com/"
                      >
                        <FaFacebookF />
                      </a>
                    </li>

                    <li>
                      <a href="https://twitter.com/">
                        <FaTwitter />
                      </a>
                    </li>
                  </ul> */}
                </div>
              </div>
              {/* End Single Team  */}
            </div>
          </div>
        </div>
        {/* End Team Area  */}
        {/* Start Finding Us Area  */}
        <div className="rn-finding-us-area rn-finding-us bg_color--1">
          <div className="inner">
            <div className="content-wrapper">
              <div className="content">
                <h4 className="theme-gradient">
                  Budite i Vi deo United Fitness tima, očekujemo Vas!
                </h4>
                <p>Naši treninzi se ne snimaju, već ih radimo uživo sa Vama!</p>
                <Link className="rn-btn btn-white" to="/plans">
                  Paketi
                </Link>
              </div>
            </div>
            <div className="thumbnail">
              <div className="image">
                <img
                  src="/assets/images/about/online_pt.jpg"
                  alt="Finding Images"
                />
              </div>
            </div>
          </div>
        </div>
        {/* End Finding Us Area  */}

        {/* Start Testimonial Area */}
        <div className="rn-testimonial-area bg_color--5 ptb--120">
          <div className="container">
            <Testimonial />
          </div>
        </div>
        {/* End Testimonial Area */}

        {/* Start Brand Area */}

        {/* End Brand Area */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </React.Fragment>
    );
  }
}
export default About;
