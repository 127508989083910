import React from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "./common/Breadcrumb";
import CounterOne from "./counters/CounterOne";
import Testimonial from "./Testimonial";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/HeaderThree";
import Footer from "../component/footer/Footer";
import { Helmet } from "react-helmet";
import { Link, useParams } from "react-router-dom";
const serviceD = [
  {
    id: "trener1",
    ime: "Vladan",
    zvanje: "Diplomirani Master profesor fizičkog vaspitanja",
    tekst:
      "Diplomirani Master profesor fizičkog vaspitanja, sa dugogodišnjim iskustvom u radu sa decom i odraslima, individualnim kao i grupnim treninzima. Moj zadatak je da motivišem, svojim znanjem i iskustvom pomognem ljudima da dođu do željene forme u kojoj se najbolje osećaju. Svaki početak zna da bude težak, ali u tome je i veća satisfakcija kod ostvarenja konačnog cilja.",
    slika: "/assets/images/team/trener1.png",
    meta: "ovde ide meta tekst1",
  },
  {
    id: "trener2",
    ime: "Marko",
    zvanje: `Strukovni trener u rekreaciji`,
    tekst: `Strukovni trener u rekreaciji, specijalista u treningu sa sopstvenim telom kao opterećanjem, nutricionista.
Bivši takmičar u taekvondu i takmičar u kalistenici ( street workout-u ). Dugogodišnje iskustvo u radu sa rekreativcima i sportistima.
Tu sam za Vas, da Vas ohrabrim i usmerim ka postizanju željene forme, ne sumnjajte u sebe, trudite se svakodnevno i uspeh je zagarantovan!`,
    slika: "/assets/images/team/trener2.png",
    meta: "ovde ide meta tekst2",
  },
];

export const AboutDetails = () => {
  const params = useParams();
  const service = serviceD.find((service) => service.id === params.trenerId);

  return (
    <React.Fragment>
      <PageHelmet pageTitle="O treneru" />
      <Helmet>
        <meta
          name="description"
          key="description"
          content={`${service.tekst}`}
        />
        <meta
          property="og:title"
          content="O treneru || United Fitness || Personalni Trener"
          key="og:title"
        />
        <meta
          property="og:url"
          key="og:url"
          content="https://www.united-fitness-personalni.com/about"
        />
        <link
          rel="canonical"
          key="canonical"
          href="https://www.united-fitness-personalni.com/about"
        />
      </Helmet>
      <Header
        headertransparent="header--transparent"
        colorblack="color--black"
        logoname="logo.png"
      />
      {/* Start Breadcrump Area */}
      <Breadcrumb title={"O nama"} />
      {/* End Breadcrump Area */}

      {/* Start About Area  */}
      <div className="rn-about-area ptb--120 bg_color--1">
        <div className="rn-about-wrapper">
          <div className="container">
            <div className="row row--35 align-items-center">
              <div className="col-lg-5">
                <div className="thumbnail">
                  <img
                    className="w-100"
                    src={service.slika}
                    alt="About Images"
                  />
                </div>
              </div>
              <div className="col-lg-7">
                <div className="about-inner inner">
                  <div className="section-title">
                    <h2 className="title">{service.ime}</h2>
                    <h4 className="title mb--50 mt--50">{service.zvanje}</h4>
                    <p className="description">{service.tekst}</p>
                  </div>
                </div>
                <div className="service-btn pt--120">
                  <Link className="btn-transparent rn-btn-dark" to="/about">
                    <span className="text">Pogledaj ceo tim</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End About Area  */}

      {/* Start CounterUp Area */}
      <div className="rn-counterup-area pb--120 bg_color--1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title text-center">
                <h3 className="fontWeight500">Our Fun Facts</h3>
              </div>
            </div>
          </div>
          <CounterOne />
        </div>
      </div>
      {/* End CounterUp Area */}

      {/* Start Testimonial Area */}
      <div className="rn-testimonial-area bg_color--5 ptb--120">
        <div className="container">
          <Testimonial />
        </div>
      </div>
      {/* End Testimonial Area */}

      {/* Start Brand Area */}

      {/* End Brand Area */}

      {/* Start Back To Top */}
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
      {/* End Back To Top */}

      <Footer />
    </React.Fragment>
  );
};
