import React from "react";
import { FaTag } from "react-icons/fa";
import "./card.scss";

function CardService() {
  return (
    // <div className="cardCont">
    <section className="light ">
      <div className="container py-2">
        <div className="h1 text-center text-dark" id="pageHeaderTitle">
          Ponude planova ishrane
        </div>
        <article className="postcard light green">
          <img
            className="postcard__img"
            src="/assets/slike/ishrana/custom.jpg"
            alt="Custom isharana"
          />
          <div className="postcard__text t-dark">
            <h1 className="postcard__title green">
              <p href="#">Custom</p>
            </h1>

            <div className="postcard__bar"></div>
            <div className="postcard__preview-txt">
              Plan ishrane se pravi na osnovu namirnica koje Vama odgovaraju
            </div>

            <ul class="postcard__tagbox">
              <li class="tag__item">
                <FaTag />
                Preporučeno
              </li>
            </ul>
          </div>
        </article>
        <article className="postcard light blue">
          <img
            className="postcard__img"
            src="/assets/slike/ishrana/mediteranska.jpg"
            alt="Mediteranska isharana"
          />
          <div className="postcard__text t-dark">
            <h1 className="postcard__title blue">
              <p href="#">Mediteranska</p>
            </h1>
            <div className="postcard__subtitle small"></div>
            <div className="postcard__bar"></div>
            <div className="postcard__preview-txt">
              Ukusna i uravnotežena ishrana, odlična za imunitet
            </div>
          </div>
        </article>
        <article className="postcard light red">
          <img
            className="postcard__img"
            src="/assets/slike/ishrana/keto.jpg"
            alt="keto isharana"
          />
          <div className="postcard__text t-dark">
            <h1 className="postcard__title red">
              <p href="#">Keto</p>
            </h1>

            <div className="postcard__bar"></div>
            <div className="postcard__preview-txt">
              Brzo skidanje viška kilograma uz ishranu baziranu na mastima i
              proteinima
            </div>
          </div>
        </article>
        <article className="postcard light green">
          <img
            className="postcard__img"
            src="/assets/slike/ishrana/vegetarijanska.jpg"
            alt="Keto ishrana"
          />
          <div className="postcard__text t-dark">
            <h1 className="postcard__title green">
              <p href="#">Vegetarijanska</p>
            </h1>

            <div className="postcard__bar"></div>
            <div className="postcard__preview-txt">
              Bazirana na namirnicama biljnog porekla, uz konzumiranje mlečnih
              proizvoda ili jaja, povećava vitalnost i energičnost celog
              organizma.
            </div>
          </div>
        </article>
        <article className="postcard light yellow">
          <img
            className="postcard__img"
            src="/assets/slike/ishrana/veganska.jpg"
            alt="Vegan ishrana"
          />
          <div className="postcard__text t-dark">
            <h1 className="postcard__title yellow">
              <p href="#">Vegan</p>
            </h1>

            <div className="postcard__bar"></div>
            <div className="postcard__preview-txt">
              Zasnovana isključivo na namirnicama biljnog porekla, omogućava
              lakše topljenje masnih naslaga
            </div>
          </div>
        </article>
      </div>
    </section>
    // </div>
  );
}

export default CardService;
