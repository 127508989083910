import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
class Testimonial extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-lg-12">
            <Tabs>
              <TabPanel>
                <div className="rn-testimonial-content text-center">
                  <div className="inner">
                    <p>
                      Preporučio bih svima, Vladan i Marko su odlični treneri,
                      uz njihovu pomoć sam ostvario svoj cilj i nastavio
                      saradnju
                      <span role="img" aria-label="emoji">
                        💪💪💪
                      </span>
                    </p>
                  </div>
                  <div className="author-info">
                    <h6>
                      <span>Miki</span>
                    </h6>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="rn-testimonial-content text-center">
                  <div className="inner">
                    <p>
                      Imala sam bolove u leđima pri svakom savijanju, uz pomoć
                      vrhunskih trenera sada mogu da obavljem sve pokrete sa
                      lakoćom. Izuzetno mi znače njihovi saveti i dostupnost.
                      Pohvale za sjajnu saradnju i stručnost, radujem se daljoj
                      saradnji.
                    </p>
                  </div>
                  <div className="author-info">
                    <h6>
                      <span>Jelena</span>
                    </h6>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="rn-testimonial-content text-center">
                  <div className="inner">
                    <p>
                      Momci su legende, uvek je zanimljivo sa njima na
                      treninzima{" "}
                      <span role="img" aria-label="emoji">
                        🙌🙌
                      </span>
                    </p>
                  </div>
                  <div className="author-info">
                    <h6>
                      <span>Petar</span>
                    </h6>
                  </div>
                </div>
              </TabPanel>{" "}
              <TabPanel>
                <div className="rn-testimonial-content text-center">
                  <div className="inner">
                    <p>
                      Vlada i Mare su veoma pažljivi i posvećeni, uvek se
                      potrude da pravilno izvodim vežbe i svakim treningom se
                      osećam sve motivsanije, preporučila bih svakom ko želi da
                      se oseća zdravo i jako!
                    </p>
                  </div>
                  <div className="author-info">
                    <h6>
                      <span>Milica</span>
                    </h6>
                  </div>
                </div>
              </TabPanel>{" "}
              <TabPanel>
                <div className="rn-testimonial-content text-center">
                  <div className="inner">
                    <p>
                      Pozdrav za United Fitness tim koji mi je omogućio da
                      dostignem odličnu kondiciju i definiciju ne napuštajući
                      kuću. Kao otac sa malom decom znači mi da ostanem u formi
                      i ispunim obaveze oko porodice, preporučujem svima koji se
                      nalaze u sličnoj situaciji, prosto je neverovatno šta se
                      može postići u kućnim uslovima!
                    </p>
                  </div>
                  <div className="author-info">
                    <h6>
                      <span>Miloš</span>
                    </h6>
                  </div>
                </div>
              </TabPanel>
              <TabList className="testimonial-thumb-wrapper">
                <Tab>
                  <div className="testimonial-thumbnai">
                    <div className="thumb">
                      <img
                        src="/assets/images/client/p3.jpg"
                        alt="Testimonial Images"
                      />
                    </div>
                  </div>
                </Tab>
                <Tab>
                  <div className="testimonial-thumbnai">
                    <div className="thumb">
                      <img
                        src="/assets/images/client/p5.jpg"
                        alt="Testimonial Images"
                      />
                    </div>
                  </div>
                </Tab>
                <Tab>
                  <div className="testimonial-thumbnai">
                    <div className="thumb">
                      <img
                        src="/assets/images/client/p1.jpg"
                        alt="Testimonial Images"
                      />
                    </div>
                  </div>
                </Tab>
                <Tab>
                  <div className="testimonial-thumbnai">
                    <div className="thumb">
                      <img
                        src="/assets/images/client/p4.jpg"
                        alt="Testimonial Images"
                      />
                    </div>
                  </div>
                </Tab>
                <Tab>
                  <div className="testimonial-thumbnai">
                    <div className="thumb">
                      <img
                        src="/assets/images/client/31.jpg"
                        alt="Testimonial Images"
                      />
                    </div>
                  </div>
                </Tab>
              </TabList>
            </Tabs>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default Testimonial;
