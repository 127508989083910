import React from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/HeaderThree";
import Footer from "../component/footer/Footer";
import { Link, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import CardService from "../component/card/Card";

const serviceD = [
  {
    id: "s1",
    naslov: "Početna testiranja",
    tekst: [
      {
        tekst:
          "Provera koordinacije, balansa i početne forme, na besplatnom probnom treningu.",
      },
      {
        tekst:
          "Formiranje treninga prema izmerenim parametrima i zahtevu klijenta.",
      },

      // { tekst: "ssssssssssss" },
    ],
    slika: "motivation.png",
    meta: "ovde ide meta tekst1",
  },
  {
    id: "s2",
    naslov: "Plan ishrane",

    tekst: [
      {
        tekst:
          "Plan ishrane Vam pomaže da na brži i efikasniji način dostignete željen izgled, pritom Vas uči kako da implementirate zdrave navike koje su održive i prijaju vašem organizmu.",
      },
      {
        tekst:
          "Personalizovani plan ishrane pruža pravilnu i uravnoteženu ishranu prilagođenu Vašim potrebama, uz koju uspešno dostižete željenu formu.",
      },
      {
        tekst:
          "Planovi ishrane se izrađuju na mesečnom nivou, mogućnost korigovanja i konsultacija je dostupna.",
      },
      //   {
      //     tekst:
      //       "Vegetarijanska-bazirana na namirnicama biljnog porekla, uz konzumiranje mlečnih proizvoda/jaja.",
      //   },
      //   {
      //     tekst:
      //       "Vegan-ne jedete životinjske proizvode, biljno bazirana ishrana je za Vas",
      //   },
      //   {
      //     tekst:
      //       "Custom-plan,preporučeno, najveći efekat postiže, ishrana napravljena po namirnicama koje Vama odgovaraju",
      //   },

      //   // { tekst: "ssssssssssss" },
    ],
    slika: "food.png",
    meta: "ovde ide meta tekst2",
    card: true,
  },
  {
    id: "s3",
    naslov: "Video trening uživo",
    tekst: [
      {
        tekst: "Uživo video treninzi preko Viber, Whats App, Zoom, Skype aplikacije",
      },

      // { tekst: "ssssssssssss" },
    ],
    slika: "pracanje.jpg",
    meta: "ovde ide meta tekst3",
  },
  {
    id: "s4",
    naslov: "Praćenje rezultata",
    tekst: [
      {
        tekst:
          "Na mesečnom nivou ili po dogovoru vršimo merenje telesne težine, procenta masnoće, procenta mišićne mase, indeksa telesne mase, bazalnog metabolizma i visceralne masnoće na našoj medicinskoj digitalnoj vagi.",
      },

      // { tekst: "ssssssssssss" },
    ],
    slika: "dt2.jpg",
    meta: "ovde ide meta tekst4",
  },
];

export const ServiceDetails = () => {
  const params = useParams();
  const service = serviceD.find((service) => service.id === params.serviceId);

  return (
    <React.Fragment>
      {/* Start Pagehelmet  */}
      <PageHelmet pageTitle={`${service.naslov} | Usluge `} />
      {/* End Pagehelmet  */}
      <Helmet>
        <meta
          name="description"
          key="description"
          content={service.tekst.map((e) => e.tekst)}
        />
        <meta property="og:title" content={service.naslov} key="og:title" />
        <meta
          property="og:url"
          key="og:url"
          content="https://www.united-fitness-personalni.com/service"
        />
        <link
          rel="canonical"
          key="canonical"
          href="https://www.united-fitness-personalni.com/service"
        />
      </Helmet>
      <Header
        headertransparent="header--transparent"
        colorblack="color--black"
        logoname="logo.png"
      />

      <div className="rn-service-details pt--150 bg_color--1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="service-details-inner">
                <div className="inner">
                  {/* Start Single Area */}
                  <div className="row sercice-details-content pb--80 align-items-center">
                    <div className="col-lg-6 col-12">
                      <div className="thumb">
                        <img
                          className="w-100"
                          src={`/assets/images/service/${service.slika}`}
                          alt="Service Images"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-12">
                      <div className="details mt_md--30 mt_sm--30">
                        <h4 className="title">{service.naslov}</h4>
                        <ul className="liststyle">
                          {service.tekst.map((e) => (
                            <li key={Math.random()}>{e.tekst}</li>
                          ))}
                          {/* <li>Yet above sewed flirted opened ouch</li>
                          <li>Goldfinch realistic sporadic ingenuous</li>
                          <li>
                            Abominable abidin far successfully then like piquan
                          </li>
                          <li>Risus commodo viverra</li>
                          <li>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </li> */}
                        </ul>
                        <div className="blog-btn" style={{ marginTop: "3em" }}>
                          <Link
                            style={{ marginRight: "2em" }}
                            className="rn-btn"
                            to={`/service`}
                          >
                            Usluge
                          </Link>
                          <Link className="rn-btn" to={`/plans`}>
                            Paketi
                          </Link>
                          {/* <a
                          className="rn-btn text-white"
                          href={`/gallery/${value.id}`}
                        >
                          Read More
                        </a> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* End Single Area */}
                  {/* End Single Area */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {service.card && (
        <CardService className="d-flex justify-content-center" />
      )}
      {/* End Page Wrapper */}

      {/* Start Back To Top */}
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
      {/* End Back To Top */}

      <Footer />
    </React.Fragment>
  );
};

export default ServiceDetails;
