const GalleryTrening = [
  {
    image: "01",
    bigImage: "/assets/slike/dt1.jpg",
    category: "slika1",
    title: "opis",
  },
  {
    image: "03",
    bigImage: "/assets/slike/dt2.jpg",
    category: "slika1",
    title: "opis",
  },
  {
    image: "03",
    bigImage: "/assets/slike/dt3.jpg",
    category: "slika1",
    title: "opis",
  },
  {
    image: "04",
    bigImage: "/assets/slike/dt4.jpg",
    category: "slika1",
    title: "opis",
  },
];
export default GalleryTrening;
