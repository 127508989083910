import React from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/HeaderThree";
import Footer from "../component/footer/Footer";

import "react-image-lightbox/style.css";
import BlogContent from "../elements/blog/BlogContent";
import { Link } from "react-router-dom";

const Gallery = () => {
  const PostList = BlogContent.slice(0, 3);
  // const service = serviceD.find((service) => service.id === params.serviceId);
  return (
    <div>
      <PageHelmet pageTitle="Galerija" />

      {/* Start Header Area  */}
      <Header
        headertransparent="header--transparent"
        colorblack="color--black"
        logoname="logo.png"
      />
      {/* End Header Area  */}

      {/* Start Breadcrump Area */}
      <Breadcrumb title={"Galerija"} />
      {/* End Breadcrump Area */}

      {/* Start Page Wrapper  */}
      <div className="rn-blog-area pt--120 bg_color--1">
        <div className="container">
          <div className="row align-items-end">
            <div className="col-lg-6 col-12">
              <div className="section-title text-left">
                {/* <h2>Galerija</h2> */}
                <h3>
                  Uz odabrani{" "}
                  <span>
                    <h1>paket</h1>{" "}
                  </span>
                  imate mogućnost korišćenja našeg prostora
                </h3>
              </div>
            </div>
            <div className="col-lg-6 col-12">
              <div className="blog-btn text-left text-lg-right mt_sm--10 mt_md--10"></div>
            </div>
          </div>
          <div className="row mt--60">
            {PostList.map((value, i) => (
              <div className="col-lg-4 col-md-6 col-12" key={i}>
                <div className="blog blog-style--1">
                  <div className="thumbnail">
                    <Link to={`/gallery/${value.id}`}>
                      <img
                        className="w-100"
                        src={`/assets/images/blog/blog-${value.images}.jpg`}
                        alt="Blog Images"
                      />
                    </Link>
                  </div>
                  <div className="content">
                    <p className="blogtype">{value.category}</p>
                    <h4 className="title">
                      <Link to="/blog-details">{value.title}</Link>
                    </h4>
                    <div className="blog-btn">
                      <Link
                        className="rn-btn text-white"
                        to={`/gallery/${value.id}`}
                      >
                        Pogledaj
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* End Page Wrapper  */}

      {/* Start Back To Top */}
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
      {/* End Back To Top */}

      {/* Start Footer Area  */}
      <Footer />
      {/* End Footer Area  */}
    </div>
  );
};

export default Gallery;
