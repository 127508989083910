import React, { Component } from "react";
import { FaInstagram, FaFacebookF } from "react-icons/fa";
import { Link } from "react-router-dom";
const SocialShare = [
  { Social: <FaFacebookF />, link: "https://www.facebook.com/" },
  {
    Social: <FaInstagram />,
    link: "http://instagram.com/united_fitness_onlinetrener/",
  },
];

class Footer extends Component {
  render() {
    return (
      <React.Fragment>
        <footer className="footer-area">
          <div className="footer-wrapper">
            <div className="row align-items-end row--0">
              <div className="col-lg-6">
                <div className="footer-left">
                  <div className="inner">
                    <span>Ready To Do This</span>
                    <h2>
                      Pravo vreme
                      <br />
                      je sada
                    </h2>
                    <Link className="rn-button-style--2" to="/plans">
                      <span>Paketi</span>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="footer-right" data-black-overlay="6">
                  <div className="row">
                    {/* Start Single Widget  */}
                    <div className="col-lg-6 col-sm-6 col-12">
                      <div className="footer-link">
                        <h4>Brzi linkovi</h4>
                        <ul className="ft-link">
                          <li>
                            <Link to="/plans">Paketi</Link>
                          </li>
                          <li>
                            <Link to="/about">O nama</Link>
                          </li>
                          <li>
                            <Link to="/contact">Kontakt</Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                    {/* End Single Widget  */}
                    {/* Start Single Widget  */}
                    <div className="col-lg-6 col-sm-6 col-12 mt_mobile--30">
                      <div className="footer-link">
                        <h4>Email</h4>
                        <ul className="ft-link">
                          <li>
                            <a href="mailto:unitedfitnessonline1878@gmail.com">
                              unitedfitnessonline1878@gmail.com
                            </a>
                          </li>

                          <li>
                            <a href="tel:0641081795">0641081795</a>
                          </li>
                          <li>
                            <a href="tel:0677090418">0677090418</a>
                          </li>
                        </ul>

                        <div className="social-share-inner">
                          <ul className="social-share social-style--2 d-flex justify-content-start liststyle mt--15">
                            {SocialShare.map((val, i) => (
                              <li key={i}>
                                <a
                                  href={`${val.link}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {val.Social}
                                </a>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                    {/* End Single Widget  */}

                    <div className="col-lg-12">
                      <div className="copyright-text">
                        <p>Copyright © 2021. All Rights Reserved.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </React.Fragment>
    );
  }
}
export default Footer;
