import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import {
  Alert,
  Button,
  ButtonGroup,
  ButtonToolbar,
  Col,
  Row,
} from "react-bootstrap";
import RangeSlider from "react-bootstrap-range-slider";
import { GenderFemale, GenderMale } from "react-bootstrap-icons";
import { Link } from "react-router-dom";

const Portfolio = () => {
  const [bmi, setBmi] = useState(0);
  const [show, setShow] = useState(false);
  const [mass, setMass] = useState(45);
  const [height, setHeight] = useState(100);
  let text;
  let variant;
  useEffect(() => {
    let bmi1 = mass / ((height / 100) * (height / 100));
    setBmi(bmi1.toFixed(1));
  }, [mass, height]);
  if (bmi <= 19.0) {
    text = (
      <div className="text-center">
        <p className="font-weight-bold">SMANJENA TELESNA TEŽINA</p>
        <p className="font-weight-light">PITAJTE SVOG LEKARA</p>
        <p>
          BMI kalkulator pokazuje da je vaša telesna težina u odnosu na visinu
          mala. Vaš organizam verovatno ne dobija dovoljno važnih hranljivih
          materija.
        </p>
      </div>
    );

    variant = "warning";
  }
  if (bmi >= 19.1 && bmi <= 25.9) {
    text = (
      <div className="text-center">
        <p className="font-weight-bold">NORMALNA TEŽINA</p>
        <p className="font-weight-light">
          SAMO TAKO NASTAVITE, U DOBROJ STE FORMI
        </p>
        <p>
          Sjajno! BMI kalkulator pokazuje da je vaša težina zdrava. Kroz
          pravilnu ishranu i dovoljno aktivnosti to će tako i ostati. Nastavite
          tako! Sve radite kako treba!
        </p>
      </div>
    );
    variant = "success";
  }
  if (bmi >= 26.0 && bmi <= 29.9) {
    text = (
      <div className="text-center">
        <p className="font-weight-bold">GOJAZNOST</p>
        <p className="font-weight-light">PROMENITE NAVIKE</p>
        <p>
          Imate nešto više kilograma. Ako se budete dovoljno kretali i promenili
          način ishrane, bez poteškoća ćete skinuti višak. Vaša idealna težina
          se nalazi na samo korak od vas!"
        </p>
      </div>
    );
    variant = "danger";
  }
  if (bmi >= 30.0) {
    text = (
      <div className="text-center">
        <p className="font-weight-bold">JAKA GOJAZNOST (ADIPOSITAS)</p>
        <p className="font-weight-light">SADA NEŠTO MORA DA SE DESI</p>
        <p>
          Merenje je pokazalo da ste jako gojazni. Radi se o bolesti metabolizma
          i pogrešnom načinu ishrane. Obratite se svom lekaru za pomoć.
        </p>
      </div>
    );
    variant = "danger";
  }

  const showBmi = () => {
    setShow(true);
  };

  return (
    <React.Fragment>
      <div className="about-wrapper">
        <div className="container">
          <div className="row row--35 align-items-center">
            <div className="col-lg-5 col-md-12">
              <div className="roundedCircle">
                <img
                  className="w-100  img-responsive"
                  src="assets/images/slike/bmi.png"
                  alt="BMI"
                  width="500"
                  height="500"
                />
              </div>
            </div>

            <div className="col-lg-7 col-md-12">
              <div className="about-inner inner">
                <div className="section-title">
                  <h2 className="title text-success">Izračunajte svoj BMI</h2>
                  <p className="description">
                    Rado biste da saznate koja je vaša idealna težina? Indeks
                    telesne mase (BMI) vam može pomoći u tome. Vaša težina se
                    deli sa vašom visinom na kvadrat. Godine i pol takođe igraju
                    ulogu u ovom procesu.
                  </p>
                </div>
                <div className="row mt--30 mt_sm--10">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                    <Form>
                      <Form.Group as={Row}>
                        <Form.Label column sm="3 h4">
                          STAROST(godine)
                        </Form.Label>
                        <Col xs="4" md="2" className="mr-auto">
                          <input type="number" min="18" max="75" size="sm" />
                        </Col>
                        <Form.Label column sm="2 h4">
                          Pol (cm)
                        </Form.Label>
                        <Col xs="3">
                          <ButtonToolbar aria-label="Toolbar with button groups">
                            <ButtonGroup
                              className="mr-2"
                              aria-label="First group"
                            >
                              <Button variant="success" size="lg">
                                <GenderMale />
                              </Button>{" "}
                              <Button variant="success" size="lg">
                                <GenderFemale />
                              </Button>
                            </ButtonGroup>
                          </ButtonToolbar>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row}>
                        <Form.Label column sm="3 h4">
                          TEŽINA (kg)
                        </Form.Label>
                        <Col xs="9" md="6">
                          <RangeSlider
                            value={mass}
                            onChange={(e) => setMass(e.target.value)}
                            size="lg"
                            tooltipPlacement="top"
                            tooltip="on"
                            min={45}
                            max={145}
                            variant="success"
                          />
                        </Col>
                        <Col xs="3">
                          <input
                            className="text-center font-weight"
                            min={45}
                            max={145}
                            type="number"
                            value={mass}
                            onChange={(e) => setMass(e.target.value)}
                            size="lg"
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row}>
                        <Form.Label column sm="3 h4">
                          VISINA (cm)
                        </Form.Label>
                        <Col xs="9" md="6">
                          <RangeSlider
                            value={height}
                            onChange={(e) => setHeight(e.target.value)}
                            size="lg"
                            tooltipPlacement="top"
                            tooltip="on"
                            min={100}
                            max={220}
                            variant="success"
                          />
                        </Col>
                        <Col xs="3">
                          <input
                            className="text-center font-weight"
                            min={100}
                            max={220}
                            type="number"
                            onChange={(e) => setHeight(e.target.value)}
                            value={height}
                            size="lg"
                          />
                        </Col>
                      </Form.Group>
                      <Alert show={show} variant={variant}>
                        <Alert.Heading className="text-center">{`TVOJ BMI: ${bmi}`}</Alert.Heading>
                        {text}
                        <hr />
                        <div className="d-flex justify-content-end">
                          <Button
                            onClick={() => setShow(true)}
                            variant={variant}
                            block
                          >
                            <Link to="/plans">
                              <p>POGLEDAJ NAŠE PAKETE</p>
                            </Link>
                          </Button>
                        </div>
                      </Alert>

                      {!show && (
                        <Button variant="success" block onClick={showBmi}>
                          IZRAČUNAJ BMI
                        </Button>
                      )}
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Portfolio;
