import React, { Fragment } from "react";
import Slider from "react-slick";
import { slideSlick } from "../page-demo/script";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/HeaderThree";
import Footer from "../component/footer/Footer";

import ServiceTwo from "../elements/service/ServiceTwo";
import CounterOne from "../elements/counters/CounterOne";
import Testimonial from "../elements/Testimonial";
import AboutTwo from "../component/HomeLayout/homeOne/AboutTwo";
import Portfolio from "../component/HomeLayout/homeOne/Portfolio";
import BlogContent from "../elements/blog/BlogContent";
import BrandTwo from "../elements/BrandTwo";
import HelmetPage from "../component/common/Helmet";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ReactGA from "react-ga";
ReactGA.initialize("UA-208632653-1");
ReactGA.pageview(window.location.pathname + window.location.search);

const SlideList = [
  {
    textPosition: "text-center",
    bgImage: "pozadina1",
    category: "",
    title: "United Fitness",
    description:
      "Naša najveća slabost leži u odustajanju. Najsigurniji način za uspeh uvek je u tome da treba da pokušamo još jednom.",
    buttonText: "Contact Us",
    buttonLink: "/contact",
  },
  {
    textPosition: "text-center",
    bgImage: "bg_image--12",
    category: "",
    title: "Kreni sada",
    description:
      "Zapamtiti, nikad nije kasno za nove početke, bez obzira na godine ili cilj.",
    buttonText: "Contact Us",
    buttonLink: "/contact",
  },
  {
    textPosition: "text-center",
    bgImage: "pozadina3",
    category: "",
    title: "Rezultat",
    description: "Uspeh je zbir malih napora, ponavljanih iz dana u dan.",
    buttonText: "Contact Us",
    buttonLink: "/contact",
  },
];

const Startup = () => {
  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     defaultToast();
  //   }, 5000);
  //   const timer2 = setTimeout(() => {
  //     infoToast();
  //   }, 7000);
  //   // return () => clearTimeout(timer, timer2);
  // }, []);
  const PostList = BlogContent.slice(0, 3);
  return (
    <Fragment>
      <HelmetPage pageTitle="United Fitness" />
      <Helmet>
        <meta
          property="og:title"
          content="United Fitness || Personalni Trener || Online Video"
          key="og:title"
        />
        <meta
          name="description"
          key="description"
          content="Naš tim se ne bavi samo konsultacijama i pripremama planova treninga, već u potpunosti posvećujemo vreme klijentu. Jedini smo u Srbiji koji uz odabrani paket online video treninga UŽIVO, preko Viber, Whats App, Zoom aplikacije, nudimo mogućnost treniranja i u fitnes centru, kao i dolazak na kućnu adresu"
        />

        <meta
          property="og:url"
          key="og:url"
          content="https://www.united-fitness-personalni.com/"
        />
        {/* <link rel="manifest" href="%PUBLIC_URL%/manifest.json" /> */}
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://i.imgur.com/o4FbClr.png" />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:image:alt" content="company logo" />
        <meta
          property="og:site_name"
          content="United Fitness || Personalni Trener || Online Video"
        />
      </Helmet>
      <Header
        headertransparent="header--transparent"
        colorblack="color--black"
        logoname="logo.png"
      />

      {/* Start Slider Area   */}
      <div className="slider-wrapper ">
        <div className="slider-activation slider-startup">
          <Slider
            className="rn-slick-dot dot-light slider-pozicija"
            {...slideSlick}
          >
            {SlideList.map((value, index) => (
              // Start Single Slider
              <div
                className={`slide slide-style-2 fullscreen d-flex align-items-center justify-content-center bg_image ${value.bgImage}`}
                key={index}
                data-black-overlay="8"
                //  ${value.bgImage}
              >
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className={`inner ${value.textPosition}`}>
                        {value.category ? <span>{value.category}</span> : ""}
                        {value.title ? (
                          <h1 className="title theme-gradient">
                            {value.title}
                          </h1>
                        ) : (
                          ""
                        )}
                        {value.description ? (
                          <p className="description">{value.description}</p>
                        ) : (
                          ""
                        )}
                        {value.buttonText ? (
                          <div className="slide-btn">
                            <Link
                              className="rn-button-style--2 btn-primary-color"
                              to="/plans"
                            >
                              Paketi
                            </Link>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              // End Single Slider
            ))}
          </Slider>
        </div>
      </div>
      {/* End Slider Area   */}

      {/* Start About Area */}
      <div className="about-area ptb--120">
        <AboutTwo />
      </div>
      {/* Start CounterUp Area */}
      <div className="rn-counterup-area pt--25 pb--110 bg_color--1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12"></div>
          </div>
          <CounterOne />
        </div>
      </div>
      {/* End CounterUp Area */}
      {/* End About Area */}
      <div className="service-area ptb--75 bg_image--3">
        <div className="container">
          <ServiceTwo />
        </div>
      </div>

      {/* Start Portfolio Area */}
      <div className="portfolio-area ptb--120 bg_color--1">
        <div className="portfolio-sacousel-inner mb--55">
          <Portfolio />
        </div>
      </div>
      {/* End Portfolio Area */}

      {/* Start Testimonial Area */}
      <div className="rn-testimonial-area bg_color--5 ptb--120">
        <div className="container">
          <Testimonial />
        </div>
      </div>
      {/* End Testimonial Area */}

      {/* Start Blog Area */}
      <div className="rn-blog-area pt--120 bg_color--1">
        <div className="container">
          <div className="row align-items-end">
            <div className="col-lg-6 col-12">
              <div className="section-title text-left">
                {/* <h2>Galerija</h2> */}
                <h3>
                  Uz odabrani{" "}
                  <span>
                    <h1>paket</h1>{" "}
                  </span>
                  imate mogućnost korišćenja našeg prostora
                </h3>
              </div>
            </div>
            <div className="col-lg-6 col-12">
              <div className="blog-btn text-left text-lg-right mt_sm--10 mt_md--10"></div>
            </div>
          </div>
          <div className="row mt--60">
            {PostList.map((value, i) => (
              <div className="col-lg-4 col-md-6 col-12" key={i}>
                <div className="blog blog-style--1">
                  <div className="thumbnail">
                    <Link to={`/gallery/${value.id}`}>
                      <img
                        className="w-100"
                        src={`/assets/images/blog/blog-${value.images}.jpg`}
                        alt="Blog Images"
                      />
                    </Link>
                  </div>
                  <div className="content">
                    <p className="blogtype">{value.category}</p>
                    <h4 className="title">
                      <Link to={`/gallery/${value.id}`}>{value.title}</Link>
                    </h4>
                    <div className="blog-btn">
                      <Link
                        className="rn-btn text-white"
                        to={`/gallery/${value.id}`}
                      >
                        Pogledaj
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* End Blog Area */}

      {/* Start Brand Area */}
      <div className="rn-brand-area brand-separation bg_color--5 ptb--120">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <BrandTwo />
            </div>
          </div>
        </div>
      </div>
      {/* End Brand Area */}

      {/* Start Back To Top */}
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
      {/* End Back To Top */}

      <Footer />
    </Fragment>
  );
};
export default Startup;
