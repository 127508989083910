import React, { Component } from "react";
import { Link } from "react-router-dom";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import { FiChevronUp } from "react-icons/fi";
import ScrollToTop from "react-scroll-up";
import Header from "../component/header/HeaderThree";
import Footer from "../component/footer/Footer";
import { Search, Stack } from "react-bootstrap-icons";
import { FaUser, FaWeight } from "react-icons/fa";
import { Helmet } from "react-helmet";
const ServiceList = [
  {
    icon: <FaWeight />,
    title: "Početna testiranja",
    description:
      "Brinemo o Vašem zdravlju, vrše se početna merenja i testiranja. Temeljno sagledavamo Vaš životni stil.  ",
    id: "s1",
  },
  {
    icon: <Stack />,
    title: "Plan ishrane",
    description:
      "Nakon izvršenih merenja, naš nutricionista sastavlja plan ishrane koji je prilagođen Vama.",
    id: "s2",
  },
  {
    icon: <FaUser />,
    title: "Video trening uživo",
    description:
      "Na osnovu početnih testiranja, plana ishrane i Vašeg željenog izgleda/cilja pravimo jedinstveni plan i program treninga.",
    id: "s3",
  },
  {
    icon: <Search />,
    title: "Praćenje rezultata",
    description:
      "Uvek smo tu za vas, konsultacije su sastavni deo celokupnog programa, zajedno idemo do ostvarenja Vašeg cilja!",
    id: "s4",
  },
];
class Service extends Component {
  render() {
    return (
      <React.Fragment>
        <PageHelmet pageTitle="Usluge " />
        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
        />
        <Helmet>
          <meta
            name="description"
            key="description"
            content="Cutting-edge software, mobile app, and website development services with sleek custom designs - get a free online estimate instantly!"
          />
          <meta
            property="og:title"
            content="Bringing West Coast Technology to the Midwest | Services"
            key="og:title"
          />
          <meta
            property="og:url"
            key="og:url"
            content="https://www.united-fitness-personalni.com/service"
          />
          <link
            rel="canonical"
            key="canonical"
            href="https://www.united-fitness-personalni.com/service"
          />
        </Helmet>

        {/* Start Breadcrump Area */}
        <Breadcrumb title={"Usluge"} />
        {/* End Breadcrump Area */}

        {/* Start Service Area */}
        <div className="service-area ptb--120 bg_color--1">
          <div className="container">
            <div className="row"></div>
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title text-center mb--30">
                  <img src="/test1.png" alt="trener" />
                </div>
              </div>
            </div>

            <div className="row service-one-wrapper">
              {ServiceList.map((val, i) => (
                <div
                  id={val.id}
                  className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12"
                  key={i}
                >
                  <Link to={`/service-details/${val.id}`}>
                    <div className="service service__style--2">
                      <div className="icon">{val.icon}</div>
                      <div className="content">
                        <h3 className="title">{val.title}</h3>
                        <p>{val.description}</p>
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* End Service Area */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </React.Fragment>
    );
  }
}
export default Service;
