const GalleryTeretana = [
  {
    image: "06",
    bigImage: "/assets/slike/t1.jpg",
    category: "slika teretana",
    title: "slika opis",
  },
  {
    image: "02",
    bigImage: "/assets/slike/t2.jpg",
    category: "naslov",
    title: "slika opis",
  },
  // {
  //   image: "03",
  //   bigImage: "/assets/images/portfolio/big/dp-big--portfolio-03.jpg",
  //   category: "naslov",
  //   title: "slika opis",
  // },
  // {
  //   image: "04",
  //   bigImage: "/assets/images/portfolio/big/dp-big--portfolio-04.jpg",
  //   category: "naslov",
  //   title: "slika opis",
  // },
];
export default GalleryTeretana;
