import React from "react";
import { FiCheck } from "react-icons/fi";
import { GiStarFormation } from "react-icons/gi";
import { HiUserGroup } from "react-icons/hi";
import { Link } from "react-router-dom";
export const Paketi = () => {
  return (
    <section
      id="pricing"
      className="pricing-content section-padding mb--100 d-flex justify-content-center"
    >
      <div className="container">
        <div className="section-title text-center">
          <h1>Cena Paketa</h1>
        </div>
        <div className="row">
          <div
            className="col-lg-4 col-sm-6 col-xs-12 wow fadeInUp"
            data-wow-duration="1s"
            data-wow-delay="0.1s"
            data-wow-offset="0"
          >
            <div className="single-pricing">
              <div className="price-head text-center">
                <h2>Light</h2>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </div>
              <h1 className="price text-center">4.800 RSD</h1>

              <ul>
                <li>
                  {" "}
                  <FiCheck size="25" /> 4 treninga
                </li>
              </ul>
              <div className="text-center">
                <Link to="/contact">Get start</Link>
              </div>
            </div>
          </div>
          <div
            className="col-lg-4 col-sm-6 col-xs-12 wow fadeInUp"
            data-wow-duration="1s"
            data-wow-delay="0.2s"
            data-wow-offset="0"
          >
            <div className="single-pricing">
              <div className="price-head text-center">
                <h2>Standard</h2>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </div>
              <h1 className="price text-center">12.000 RSD</h1>

              <ul>
                <li>
                  <FiCheck size="25" /> 10 treninga
                </li>
                <li>
                  <FiCheck size="25" /> Merenje na mesečnom nivou
                </li>
                <li>
                  <FiCheck size="25" />
                  Na svaku treću uplatu poklon rekvizit za vežbanje
                </li>
              </ul>
              <div className="text-center">
                <Link to="/contact">Get start</Link>
              </div>
            </div>
          </div>
          <div
            className="col-lg-4 col-sm-6 col-xs-12 wow fadeInUp"
            data-wow-duration="1s"
            data-wow-delay="0.2s"
            data-wow-offset="0"
          >
            <div className="single-pricing">
              <div className="price-head text-center">
                <h2>Premium</h2>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </div>
              <h1 className="price text-center">22.000 RSD</h1>
              <h1 className="text-center old-price">24.000 RSD</h1>

              <ul>
                <li>
                  <FiCheck size="25" />
                  20 treninga
                </li>
                <li>
                  <FiCheck size="25" />
                  Merenje na mesečnom nivou
                </li>
                <li>
                  <FiCheck size="25" />
                  Na svaku treću uplatu poklon rekvizit za vežbanje
                </li>
              </ul>
              <div className="text-center">
                <Link to="/contact">Get start</Link>
              </div>
            </div>
          </div>
          <div
            className="col-lg-4 col-sm-6 col-xs-12 wow fadeInUp pt--100"
            data-wow-duration="1s"
            data-wow-delay="0.2s"
            data-wow-offset="0"
          >
            <div className="single-pricing">
              <div className="price-head text-center">
                <h2>VIP</h2>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </div>
              <p className="spec text-center">
                <HiUserGroup size="30" />
                *cena se formira u zavisnosti od lokacije i broja treninga
              </p>
              <ul>
                <li>
                  <FiCheck size="25" />
                  Dolazak na kućnu adresu na teritoriji Beograda
                </li>
                <li>
                  <FiCheck size="25" />
                  Plan ishrane
                </li>
                <li>
                  <FiCheck size="25" />
                  Merenje
                </li>
                <li>
                  <FiCheck size="25" />
                  Masaža
                </li>
              </ul>
              <div className="text-center">
                <Link to="/contact">Get start</Link>
              </div>
            </div>
          </div>
          <div
            className="col-lg-4 col-sm-6 col-xs-12 wow fadeInUp pt--100"
            data-wow-duration="1s"
            data-wow-delay="0.3s"
            data-wow-offset="0"
          >
            <div className="single-pricing single-pricing-white spec2">
              <div className="price-head text-center ">
                <h2>GYM PAKET</h2>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </div>
              {/* <span className="price-label">PREPORUCENO</span> */}

              {/*  */}
              <ul>
                <li className="text-center">
                  {" "}
                  Uz odabrani STANDARD ili PREMIUM PAKET, jedinstvena prilika
                  korišćenja Vaših treninga u fitnes centru ,,Good Life Fitness"
                  u Žarkovu, Dušana Vlajića 20 a sa United Fitness timom i
                  korišćenje saune sa popustom za članove United Fitness tima.
                </li>
                <li className="text-center">
                  <GiStarFormation size="50" />
                </li>
                {/* <li>
                  <FiCheck size="25" />
                  50GB Disk Space
                </li>
                <li>
                  <FiCheck size="25" />
                  50 Email Accounts
                </li>
                <li>
                  <FiCheck size="25" />
                  50GB Mesecno Bandwidth
                </li>
                <li>
                  <FiCheck size="25" />
                  10 Subdomains
                </li>
                <li>
                  <FiCheck size="25" />
                  15 Domains
                </li>
                <li>
                  <FiCheck size="25" />
                  Unlimited Support
                </li> */}
              </ul>
              <div className="text-center">
                <Link to="/contact">Get start</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
