import React, { useEffect, useState } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/HeaderThree";
import Footer from "../component/footer/Footer";

import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { useParams } from "react-router";
import GalleryTeretana from "../elements/gallery/GalleryTeretana";
import GalleryTrening from "../elements/gallery/GalleryTrening";
import { Helmet } from "react-helmet";
import GallerySpa from "../elements/gallery/GallerySpa";
const Gallery = () => {
  const [tab1, setTab1] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [gallery, setGallery] = useState(GalleryTrening);
  const openHandler = (e) => {
    setIsOpen(true);
    setTab1(e);
  };
  const params = useParams();
  let id = params.galleryId;
  useEffect(() => {
    if (id === "teretana") {
      setGallery(GalleryTeretana);
    }
    if (id === "trening") {
      setGallery(GalleryTrening);
    }
    if (id === "spa") {
      setGallery(GallerySpa);
    }
  }, [id]);

  console.log(id);
  // const service = serviceD.find((service) => service.id === params.serviceId);
  return (
    <div>
      <PageHelmet pageTitle="Galerija" />
      <Helmet>
        <meta
          name="description"
          key="description"
          content="Cutting-edge software, mobile app, and website development services with sleek custom designs - get a free online estimate instantly!"
        />
        <meta
          property="og:title"
          content="Bringing West Coast Technology to the Midwest | Services"
          key="og:title"
        />
        <meta
          property="og:url"
          key="og:url"
          content="https://www.united-fitness-personalni.com/gallery"
        />
        <link
          rel="canonical"
          key="canonical"
          href="https://www.united-fitness-personalni.com/gallery"
        />
      </Helmet>
      {/* Start Header Area  */}
      <Header
        headertransparent="header--transparent"
        colorblack="color--black"
        logoname="logo.png"
      />
      {/* End Header Area  */}

      {/* Start Breadcrump Area */}
      <Breadcrumb title={"Galerija"} />
      {/* End Breadcrump Area */}

      {/* Start Page Wrapper  */}
      <main className="page-wrapper">
        {/* Start Portfolio Area  */}
        <div className="rn-portfolio-area ptb--120 bg_color--1 line-separator">
          <div className="container">
            <div className="row d-flex justify-content-center">
              {gallery.map((value, index) => (
                <div className="col-lg-6 col-xs-12" key={index}>
                  {isOpen && (
                    <Lightbox
                      mainSrc={gallery[tab1].bigImage}
                      nextSrc={gallery[(tab1 + 1) % gallery.length]}
                      prevSrc={
                        gallery[(tab1 + gallery.length - 1) % gallery.length]
                      }
                      onCloseRequest={() => setIsOpen(false)}
                      onMovePrevRequest={() =>
                        setTab1((tab1 + gallery.length - 1) % gallery.length)
                      }
                      onMoveNextRequest={() =>
                        setTab1((tab1 + 1) % gallery.length)
                      }
                    />
                  )}
                  <div className="item-portfolio-static">
                    <div onClick={() => openHandler(index)}>
                      <div className="portfolio-static">
                        <div className="thumbnail-inner">
                          <div className="thumbnail">
                            <img
                              src={gallery[index].bigImage}
                              alt="Portfolio Images"
                            />
                          </div>
                        </div>
                        <div className="content"></div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* End Portfolio Area  */}

        {/* Start Portfolio Area  */}
        {/* <div className="creative-portfolio-wrapper ptb--120 bg_color--1">
          <div className="container plr--10">
            <div className="row row--5">
              {PortfolioList.map((value, i) => (
                <div className="col-lg-4 col-md-6 col-12" key={i}>
                  <div className="portfolio-style--3">
                    <div className="thumbnail">
                      <a href="/portfolio-details">
                        <img
                          className="w-100"
                          src={`/assets/images/portfolio/portfolio-${value.images}.jpg`}
                          alt="Portfolio Images"
                        />
                      </a>
                    </div>
                    <div className="content">
                      <p className="portfoliotype">{value.category}</p>
                      <h4 className="title">
                        <a href="/portfolio-details">{value.title}</a>
                      </h4>
                      <div className="portfolio-btn">
                        <a
                          className="rn-btn text-white"
                          href="/portfolio-details"
                        >
                          Read More
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div> */}
        {/* End Portfolio Area  */}
      </main>
      {/* End Page Wrapper  */}

      {/* Start Back To Top */}
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
      {/* End Back To Top */}

      {/* Start Footer Area  */}
      <Footer />
      {/* End Footer Area  */}
    </div>
  );
};

export default Gallery;
