import React, { Component } from "react";
import { Search, Stack } from "react-bootstrap-icons";
import { FaUser, FaWeight } from "react-icons/fa";
import { Link } from "react-router-dom";

const ServiceList = [
  {
    icon: <FaWeight />,
    title: "Početna testiranja",
    description:
      "Brinemo o Vašem zdravlju, vrše se početna merenja i testiranja. Temeljno sagledavamo Vaš životni stil.  ",
    id: "s1",
  },
  {
    icon: <Stack />,
    title: "Plan ishrane",
    description:
      "Nakon izvršenih merenja, naš nutricionista sastavlja plan ishrane koji je prilagođen Vama.",
    id: "s2",
  },
  {
    icon: <FaUser />,
    title: " Video trening uživo",
    description:
      "Na osnovu početnih testiranja na probnom treningu, plana ishrane i Vašeg željenog izgleda/cilja pravimo jedinstveni plan i program treninga.",
    id: "s3",
  },
  {
    icon: <Search />,
    title: "Praćenje rezultata",
    description:
      "Uvek smo tu za vas, konsultacije su sastavni deo celokupnog programa, zajedno idemo do ostvarenja Vašeg cilja!",
    id: "s4",
  },
];

class ServiceTwo extends Component {
  render() {
    let title = "Usluge",
      description =
        "Na osnovu odrađenih merenja i testiranja funkcionalnih i motoričkih sposobnosti i u zavisnosti od Vašeg zdravstvenog stanja planiramo i programiramo trenažni proces i način ishrane u zavisnosti od cilja treninga.";
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-lg-4 col-12">
            <div className="section-title mt--30 mt_md--5 mt_mobile--5 mb_mobile--10">
              <h2 className="title">{title}</h2>
              <p>{description}</p>
              <div className="service-btn">
                <Link className="btn-transparent rn-btn-dark" to="/service">
                  <span className="text">Pogledaj detaljnije</span>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-8 col-12 mt_md--50">
            <div className="row service-one-wrapper">
              {ServiceList.map((val, i) => (
                <div className="col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
                  <Link
                    className="text-center"
                    to={`/service-details/${val.id}`}
                  >
                    <div className="service service__style--2">
                      <div className="icon">{val.icon}</div>
                      <div className="content">
                        <h3 className="title">{val.title}</h3>
                        <p>{val.description}</p>
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default ServiceTwo;
