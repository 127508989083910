// React Required
import React from "react";
import ReactDOM from "react-dom";
// import * as emailjs from "emailjs-com";
import "bootstrap/dist/css/bootstrap.css"; // or include from a CDN
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";
// Create Import File
import "./index.scss";

import PageScrollTop from "./component/PageScrollTop";

// Home layout
import Startup from "./home/Startup";

// Element Layout
import Service from "./elements/Service";
import ServiceDetails from "./elements/ServiceDetails";
import About from "./elements/About";
import Contact from "./elements/Contact";
import PortfolioDetails from "./elements/PortfolioDetails";
// import Blog from "./elements/Blog";
import BlogDetails from "./elements/BlogDetails";
import error404 from "./elements/error404";

// Blocks Layout

import Team from "./blocks/Team";
import Testimonial from "./blocks/Testimonial";
import Portfolio from "./blocks/Portfolio";
import Gallery from "./blocks/Gallery";
import Gallery2 from "./blocks/Gallery2";
import ProgressBar from "./blocks/ProgressBar";
import ContactForm from "./blocks/ContactForm";
// import GoogleMap from "./blocks/GoogleMap";
import PricingTable from "./blocks/PricingTable";

import { BrowserRouter, Switch, Route, Link } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import { Plans } from "./elements/Plans";
import { Helmet } from "react-helmet";
import { AboutDetails } from "./elements/AboutDetails";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactGA from "react-ga";
ReactGA.initialize("G-5MBCL7C6J5");
ReactGA.pageview(window.location.pathname + window.location.search);

const Root = () => {
  const defaultToast = () => {
    toast("Pozdrav od Trenera", {
      position: "top-center",
      autoClose: 5000,
      draggable: true,
    });
  };
  const infoToast = () => {
    toast.info("Svaki probni trening je BESPLATAN!", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      draggable: true,
    });
  };
  setTimeout(() => {
    defaultToast();
  }, 5000);
  setTimeout(() => {
    infoToast();
  }, 7000);
  return (
    <BrowserRouter basename={"/"}>
      <Helmet>
        <title key="title">United Fitness || Personalni Trener</title>
        <meta
          name="description"
          content="United Fitness Beograd bavi se konsultacijama i pripremama planova treninga.ONLINE treninzi, mogućnost treniranja u fitnes centru, kao i dolazak na kućnu adresuu"
        />
        <meta
          property="og:description"
          content="Personalni trening. Jedini smo u Srbiji koji uz odabrani paket online video treninga UŽIVO, preko Viber, Whats App, Zoom aplikacije, nudimo mogućnost treniranja i u fitnes centru, kao i dolazak na kućnu adresu ( sa istezanjem i merenjem na digitalnoj medicinskoj vagi ).."
        />
        <meta
          name="google-site-verification"
          content="fuUmOj8IOABdtEz2V_C5p_vDKx85QxMxoEI5NIuX_jE"
        />
        <meta
          name="googlebot"
          content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
        />
        <meta
          name="bingbot"
          content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
        />
        <meta
          property="og:url"
          content="https://www.united-fitness-personalni.com/"
        />
        <meta property="og:locale" content="sr_RS" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://i.imgur.com/o4FbClr.png" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:image:alt" content="company logo" />
      </Helmet>

      <PageScrollTop>
        <Link to="/contact">
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            draggable
            pauseOnHover
          />
        </Link>
        <Switch>
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/`}
            component={Startup}
          />

          {/* Element Layot */}
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/service`}
            component={Service}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/service-details/:serviceId`}
            component={ServiceDetails}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/aboutDetails/:trenerId`}
            component={AboutDetails}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/contact`}
            component={Contact}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/about`}
            component={About}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/portfolio-details`}
            component={PortfolioDetails}
          />
          {/* <Route
              exact
              path={`${process.env.PUBLIC_URL}/blog`}
              component={Blog}
            /> */}
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/blog-details`}
            component={BlogDetails}
          />

          {/* Blocks Elements  */}
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/team`}
            component={Team}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/testimonial`}
            component={Testimonial}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/portfolio`}
            component={Portfolio}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/gallery/:galleryId`}
            component={Gallery}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/gallery/`}
            component={Gallery2}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/progressbar`}
            component={ProgressBar}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/contact-form`}
            component={ContactForm}
          />
          {/* <Route
              exact
              path={`${process.env.PUBLIC_URL}/google-map`}
              component={GoogleMap}
            /> */}
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/pricing-table`}
            component={PricingTable}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/plans`}
            component={Plans}
          />

          <Route path={`${process.env.PUBLIC_URL}/404`} component={error404} />
          <Route component={error404} />
        </Switch>
      </PageScrollTop>
    </BrowserRouter>
  );
};

ReactDOM.render(<Root />, document.getElementById("root"));
serviceWorker.register();
