import React from "react";
import { FiCheck } from "react-icons/fi";
import { Link } from "react-router-dom";
const PricingTable = () => {
  const plans = [
    {
      id: "p1",
      title: "Grupa do 3",
      price: "100",
      usluga1: "test",
      usluga2: "test",
      usluga3: "test",
      usluga4: "test",
      usluga5: "test",
      // active: true,
    },
    {
      id: "p2",
      title: "Individualni",
      price: "100",
      usluga1: "test",
      usluga2: "test",
      usluga3: "test",
      usluga4: "test",
      usluga5: "test",
      active: true,
    },
    {
      id: "p3",
      title: "Grupa do 5",
      price: "100",
      usluga1: "test",
      usluga2: "test",
      usluga3: "test",
      usluga4: "test",
      usluga5: "test",
      // active: true,
    },
  ];
  return (
    <>
      <main className="page-wrapper">
        <div className="rn-pricing-table-area pb--120 bg_color--5">
          <div className="container">
            <div className="section-title text-center  mb--30">
              <h2>Paketi</h2>
              <p>
                Ne mozes da se odlucis za odgovarajuci paket <br /> ili imas jos
                neka pitanja, tu smo za tebe da ti pomognemo.
              </p>
            </div>
            <div className="row">
              {plans.map((e) => (
                <div className="col-lg-4 col-md-6 col-12" key={e.id}>
                  <div className={`rn-pricing ${e.active ? "active" : ""}`}>
                    <div className="pricing-table-inner">
                      <div className="pricing-header">
                        <h4 className="title">{e.title}</h4>
                        <div className="pricing">
                          <span className="price">{e.price}</span>
                          <span className="subtitle">EUR Mesecno</span>
                        </div>
                      </div>
                      <div className="pricing-body">
                        <ul className="list-style--1">
                          <li>
                            <FiCheck /> {e.usluga1}
                          </li>
                          <li>
                            <FiCheck /> {e.usluga2}
                          </li>
                          <li>
                            <FiCheck /> {e.usluga3}
                          </li>
                          <li>
                            <FiCheck /> {e.usluga4}
                          </li>
                          <li>
                            <FiCheck /> {e.usluga5}
                          </li>
                        </ul>
                      </div>
                      <div className="pricing-footer">
                        <Link className="rn-btn" to="/contact">
                          Saznas Vise
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>{" "}
      </main>
    </>
  );
};
export default PricingTable;
