const GallerySpa = [
  {
    image: "01",
    bigImage: "/assets/slike/s1.jpg",
    category: "slika1",
    title: "opis",
  },
  {
    image: "03",
    bigImage: "/assets/slike/s2.jpg",
    category: "slika1",
    title: "opis",
  },
  {
    image: "03",
    bigImage: "/assets/slike/s3.jpg",
    category: "slika1",
    title: "opis",
  },
  // {
  //   image: "04",
  //   bigImage: "/assets/images/portfolio/big/t.jpg",
  //   category: "slika1",
  //   title: "opis",
  // },
];
export default GallerySpa;
