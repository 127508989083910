import React, { Component } from "react";
import { Link } from "react-router-dom";

class AboutTwo extends Component {
  render() {
    let title = "United Fitness Team",
      description =
        "Naš tim se ne bavi samo konsultacijama i pripremama planova treninga, već u potpunosti posvećujemo vreme klijentu. U prostoru vašeg doma ili objekta koji Vama odgovara u unapred zakazano vreme, video pozivom preko Viber, Whats App, Zoom, Skype aplikacije sprovodimo personalne treninge 1 na 1 ( do 2 klijenta istovremeno ), gde su treninzi bazirani prema Vašim potrebama i ciljevima, uz konstantnu komunikaciju i korigovanje tokom treninga. ";
    return (
      <React.Fragment>
        <div className="about-wrapper">
          <div className="container">
            <div className="row row--35 align-items-center">
              <div className="col-lg-6 col-md-12">
                <div className="thumbnail">
                  <div className="column">
                    <img
                      className="w-100"
                      src="/test1.png"
                      alt="About Images"
                    />

                    <img
                      className="w-100"
                      src="/assets/images/service/trening.png"
                      alt="About Images"
                    />
                  </div>
                </div>
              </div>

              <div className="col-lg-6 col-md-12">
                <div className="about-inner inner">
                  <div className="section-title">
                    <h1 className="title">{title}</h1>
                    <p className="description">{description}</p>
                  </div>
                  <div className="row mt--30 mt_sm--10">
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                      <div className="about-us-list">
                        <h3 className="title">O timu</h3>
                        <p>
                          United Fitness Team čine diplomirani profesor fizičkog
                          vaspitanja Vladan Mirić i strukovni trener u
                          rekreaciji Marko Obradović. Treneri sa višegodišnjim
                          iskustvom u poljima fitnesa, takmičarskih disciplina,
                          grupnih treninga, kao i nutricionizma. Već dugi niz
                          godina radimo sa rekreativcima, sportistima i svima
                          onima koji žele da se osećaju zadovoljno, sposobno i
                          zdravo u svome telu. Naš zadatak je da našu saradnju
                          učinimo zanimljivom, lakšom, a sve u cilju postizanja
                          konačnog rezultata i Vaše željene forme u udobnosti
                          Vašeg doma ili prostora koji Vama odgovara.
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                      <div className="about-us-list">
                        <h2 className="title">Šta nas čini posebnim</h2>
                        <p>
                          <strong>Jedini smo u Srbiji</strong> koji uz odabrani
                          paket online video treninga UŽIVO, nudimo mogućnost
                          treniranja i u fitnes centru, kao i dolazak na kućnu
                          adresu ( sa istezanjem i merenjem na digitalnoj
                          medicinskoj vagi ).
                        </p>
                      </div>
                    </div>
                    {/* <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                      <div className="about-us-list">
                        <h3 className="title">Ko smo mi</h3>
                        <p>
                          There are many vtions of passages of Lorem Ipsum
                          available, but the majority have suffered.
                        </p>
                      </div>
                    </div> */}
                  </div>
                </div>
                <div className="service-btn mt--50 ">
                  <Link className="btn-transparent rn-btn-dark" to="/about">
                    <span className="text">Pogledaj detaljnije</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="rn-team-area bg_color--1 pt--120">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title service-style--3 text-center mb--25">
                  <h2 className="title">Naš Tim</h2>
                </div>
              </div>
            </div>
            <div className="row d-flex justify-content-center">
              {/* Start Single Team  */}
              <div className=" col-lg-4 col-md-6 col-sm-6 col-12">
                <div className="team">
                  <div className="thumbnail">
                    <img
                      className="w-100"
                      src="/assets/images/team/trener1.png"
                      alt="Blog Images"
                    />
                  </div>
                  <div className="content">
                    <h4 className="title">Vladan</h4>
                    <p className="designation">
                      Diplomirani Master profesor fizičkog vaspitanja.
                    </p>
                    <button type="button" className="btn btn-success mt--10">
                      <Link to="/aboutDetails/trener1">O Treneru</Link>
                    </button>
                  </div>
                  {/* <ul className="social-icon">
                    <li>
                      <a
                        href="https://www.facebook.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FaFacebookF />
                      </a>
                    </li>

                    <li>
                      <a
                        href="https://twitter.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FaTwitter />
                      </a>
                    </li>
                  </ul> */}
                </div>
              </div>
              {/* End Single Team  */}

              {/* Start Single Team  */}
              <div className="col-lg-4 col-md-6 col-sm-6   col-12">
                <div className="team">
                  <div className="thumbnail">
                    <Link to="/">
                      <img
                        className="w-100"
                        src="/assets/images/team/trener2.png"
                        alt="Blog Images"
                      />
                    </Link>
                  </div>
                  <div className="content">
                    <h4 className="title">Marko</h4>
                    <p className="designation">Strukovni trener u rekreaciji</p>
                    <button type="button" className="btn btn-success mt--10">
                      <Link to="/aboutDetails/trener2">O Treneru</Link>
                    </button>
                    {/* <div className="blog-btn pt--10">
                      <Link
                        className="rn-btn text-black"
                        to="/aboutDetails/trener2"
                      >
                        O Trenerudsadsadsa
                      </Link>
                    </div> */}
                  </div>

                  {/* <ul className="social-icon">
                    <li>
                      <a
                        href="https://www.facebook.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FaFacebookF />
                      </a>
                    </li>

                    <li>
                      <a
                        href="https://twitter.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FaTwitter />
                      </a>
                    </li>
                  </ul> */}
                </div>
              </div>
              {/* End Single Team  */}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default AboutTwo;
