import React, { Component } from "react";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga";
ReactGA.initialize("UA-208632653-1");
ReactGA.pageview(window.location.pathname + window.location.search);
class PageHelmet extends Component {
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>
            {this.props.pageTitle} || Personalni Trener || Online Trening ||
            Beograd
          </title>
          <meta
            name="description"
            content="Naš tim se ne bavi samo konsultacijama i pripremama planova treninga, već u potpunosti posvećujemo vreme klijentu. Jedini smo u Srbiji koji uz odabrani paket online video treninga UŽIVO, preko Viber, Whats App, Zoom aplikacije, nudimo mogućnost treniranja i u fitnes centru, kao i dolazak na kućnu adresu"
          />
          <meta
            property="og:description"
            content="Personalni trening. Jedini smo u Srbiji koji uz odabrani paket online video treninga UŽIVO, preko Viber, Whats App, Zoom aplikacije, nudimo mogućnost treniranja i u fitnes centru, kao i dolazak na kućnu adresu ( sa istezanjem i merenjem na digitalnoj medicinskoj vagi ).."
          />
          <meta
            name="google-site-verification"
            content="fuUmOj8IOABdtEz2V_C5p_vDKx85QxMxoEI5NIuX_jE"
          />
          <meta
            name="googlebot"
            content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
          />
          <meta
            name="bingbot"
            content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
          />
          <meta
            property="og:url"
            content="https://www.united-fitness-personalni.com/"
          />
          <meta property="og:locale" content="sr_RS" />
          <meta property="og:type" content="website" />
          <meta property="og:image" content="https://i.imgur.com/o4FbClr.png" />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="630" />
          <meta property="og:image:alt" content="company logo" />
          <script
            async
            src="https://www.googletagmanager.com/gtag/js?id=G-5MBCL7C6J5"
          ></script>
        </Helmet>
      </React.Fragment>
    );
  }
}

export default PageHelmet;
