const BlogContent = [
  {
    images: "01",
    title: "Delovi treninga",
    category: "Galerija",
    id: "trening",
  },
  {
    images: "02",
    title: "Naša radionica",
    category: "Galerija",
    id: "teretana",
  },

  {
    images: "03",
    title: "Opuštanje u sauni ili revitalizirajuća masaža",
    category: "Wellness centar ",
    id: "spa",
  },
  {
    images: "05",
    title: " Getting tickets to the big show",
    category: "Development",
  },
  {
    images: "06",
    title: "A big ticket gone to be an interesting ",
    category: "Management",
  },
  {
    images: "07",
    title: "The Home of the Future Could Bebes",
    category: "Design",
  },
];

export default BlogContent;
