import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import { FiHeadphones, FiMail, FiMapPin } from "react-icons/fi";
import ContactTwo from "../elements/contact/ContactTwo";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/HeaderThree";
import Footer from "../component/footer/Footer";
import Breadcrumb from "../elements/common/Breadcrumb";
import { Helmet } from "react-helmet";

class Contact extends Component {
  render() {
    return (
      <React.Fragment>
        <PageHelmet pageTitle="Kontakt" />
        <Helmet>
          <meta
            name="description"
            key="description"
            content="Let us guide you through the custom software design and development process. Send us a message with any of your ideas or questions to get started!"
          />
          <meta
            property="og:title"
            content="Bringing West Coast Technology to the Midwest | Contact Us"
            key="og:title"
          />
          <meta
            property="og:url"
            key="og:url"
            content="https://www.united-fitness-personalni.com/contact"
          />
          <link
            rel="canonical"
            key="canonical"
            href="https://www.united-fitness-personalni.com/contact.js"
          />
        </Helmet>
        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
        />
        <Breadcrumb title={"Kontakt"} />

        {/* Start Breadcrump Area */}
        {/* <div
          className="rn-page-title-area pt--120 pb--190 bg_image bg_image--17"
          data-black-overlay="6"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="rn-page-title text-center pt--100">
                  <h2 className="title theme-gradient">Kontakt</h2>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* End Breadcrump Area */}

        {/* Start Contact Top Area  */}
        <div className="rn-contact-top-area ptb--120 bg_color--5">
          <div className="container">
            <div className="row">
              {/* Start Single Address  */}
              <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                <div className="rn-address">
                  <div className="icon">
                    <FiHeadphones />
                  </div>
                  <div className="inner">
                    <h4 className="title">Kontakt telefoni</h4>
                    <p>
                      <a href="tel:+381641081795">+381 641081795</a>
                    </p>
                    <p>
                      <a href="tel:+381677090418">+381 677090418</a>
                    </p>
                  </div>
                </div>
              </div>
              {/* End Single Address  */}

              {/* Start Single Address  */}
              <div className="col-lg-4 col-md-6 col-sm-6  col-xs-12 col-12 mt_mobile--50">
                <div className="rn-address">
                  <div className="icon">
                    <FiMail />
                  </div>
                  <div className="inner">
                    <h4 className="title">Email Addresa</h4>
                    <p>
                      <a
                        className="textMail"
                        href="mailto:unitedfitnessonline1878@gmail.com@gmail.com"
                      >
                        unitedfitnessonline1878@gmail.com
                      </a>
                    </p>
                  </div>
                </div>
              </div>
              {/* End Single Address  */}

              {/* Start Single Address  */}
              <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_md--50 mt_sm--50">
                <div className="rn-address">
                  <div className="icon">
                    <FiMapPin />
                  </div>
                  <div className="inner">
                    <h4 className="title">Adresa</h4>
                    <p>
                      Dušana Vlajića 20a <br /> Beograd
                    </p>
                  </div>
                </div>
              </div>
              {/* End Single Address  */}
            </div>
          </div>
        </div>
        {/* End Contact Top Area  */}

        {/* Start Contact Page Area  */}
        <div className="rn-contact-page ptb--120 bg_color--1">
          <ContactTwo />
        </div>
        {/* End Contact Page Area  */}

        {/* Start Contact Map  */}
        {/* <div className="rn-contact-map-area position-relative">
          <div style={{ height: "650px", width: "100%" }}>
            <GoogleMapReact
              defaultCenter={this.props.center}
              defaultZoom={this.props.zoom}
            >
              <AnyReactComponent
                lat={59.955413}
                lng={30.337844}
                text="My Marker"
              />
            </GoogleMapReact>
          </div>
        </div> */}
        <div className="container rn-contact-map-area position-relative mb-5">
          <iframe
            title="map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2832.90507658807!2d20.408329315423288!3d44.76235058801945!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x475a6fde3b10daaf%3A0x13cdbf7783717e68!2sUnited%20Fitness%20Personalni%20Trener!5e0!3m2!1ssr!2srs!4v1632833493464!5m2!1ssr!2srs"
            width="800"
            height="600"
            style={{ border: 0, width: "100%" }}
            loading="lazy"
          ></iframe>
        </div>
        {/* End Contact Map  */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </React.Fragment>
    );
  }
}
export default Contact;
