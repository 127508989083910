import React from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/HeaderThree";
import Footer from "../component/footer/Footer";
import { Paketi } from "../component/price/Paketi";
import { Helmet } from "react-helmet";
// import Breadcrumb from "./common/Breadcrumb";

export const Plans = () => {
  return (
    <React.Fragment>
      {/* Start Pagehelmet  */}
      <PageHelmet pageTitle="Progarmi" />
      {/* End Pagehelmet  */}
      <Helmet>
        <meta
          name="description"
          key="description"
          content="Pogledaj Cene programa, uvek smo tu da ti pomognemo da se odlučiš za pravi.Plan ishrane Vam pomaže da na brži i efikasniji način dostignete željen izgled"
        />
        <meta
          property="og:title"
          content="Progarmi || United Fitness || Personalni Trener"
          key="og:title"
        />
        <meta
          property="og:url"
          key="og:url"
          content="https://www.united-fitness-personalni.com/plans"
        />
        <link
          rel="canonical"
          key="canonical"
          href="https://www.united-fitness-personalni.com/plans"
        />
      </Helmet>
      <Header
        headertransparent="header--transparent"
        colorblack="color--black"
        logoname="logo.png"
      />

      <div className="rn-service-details pt--120 bg_color--1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="service-details-inner">
                <div className="inner">
                  {/* Start Single Area */}
                  <div className="row t pb--80 d-flex justify-content-center">
                    <div className="thumb">
                      <img
                        className="w-100"
                        src={`/assets/images/service/trening.png`}
                        alt="Service Images"
                      />
                    </div>
                  </div>

                  {/* End Single Area */}

                  {/* End Single Area */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Page Wrapper */}
      <Paketi />

      {/* <PricingTable id="price" /> */}

      {/* Start Back To Top */}
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
      {/* End Back To Top */}

      <Footer id="price2" />
    </React.Fragment>
  );
};
