import React, { useRef } from "react";
import emailjs from "emailjs-com";
import { toast, ToastContainer } from "react-toastify";

function ContactTwo(props) {
  const nameInput = useRef();
  const emailInput = useRef();
  const subjectInput = useRef();
  const messageInput = useRef();
  const numberInput = useRef();

  function sendEmail(e) {
    e.preventDefault();
    const enteredName = nameInput.current.value;
    const enteredEmail = emailInput.current.value;
    const enteredSubject = subjectInput.current.value;
    const enteredMessage = messageInput.current.value;
    const enteredNumber = numberInput.current.value;
    const successToast = () => {
      toast.success("Uspešno poslata poruka", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
      });
    };
    const errorToast = (params) => {
      toast.error("Došlo je do greške, pokušajte kasnije", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
      });
    };

    emailjs
      .send(
        "service_personalni",
        "template_mq53fhn",
        {
          name: enteredName,
          message: enteredMessage,
          reply_to: enteredSubject,
          email: enteredEmail,
          number: enteredNumber,
        },
        "user_okJQC4Ix2ffCEfYcXM00A"
      )
      .then(
        (result) => successToast(),
        (error) => errorToast()
      );
    e.target.reset();
  }
  return (
    <div className="contact-form--1">
      <div className="container">
        <div className="row row--35 align-items-start">
          <div className="col-lg-6 order-2 order-lg-1">
            <div className="section-title text-left mb--50">
              <h2 className="title">Kontakitiraj nas</h2>
              <p className="description">
                Popuni formu, a mi Vam odgovaramo u najbržem mogućem roku!
              </p>
            </div>
            <div className="form-wrapper">
              <form onSubmit={sendEmail}>
                <label htmlFor="item01">
                  <input
                    type="text"
                    name="name"
                    id="item01"
                    ref={nameInput}
                    placeholder="Your Name *"
                  />
                </label>
                <label htmlFor="item05">
                  <input
                    type="number"
                    name="number"
                    id="item05"
                    ref={numberInput}
                    placeholder="Your Number *"
                  />
                </label>

                <label htmlFor="item02">
                  <input
                    type="text"
                    name="email"
                    id="item02"
                    ref={emailInput}
                    placeholder="Your email *"
                  />
                </label>

                <label htmlFor="item03">
                  <input
                    type="text"
                    name="subject"
                    id="item03"
                    ref={subjectInput}
                    placeholder="Write a Subject"
                  />
                </label>
                <label htmlFor="item04">
                  <textarea
                    type="text"
                    id="item04"
                    name="message"
                    ref={messageInput}
                    placeholder="Your Message"
                  />
                </label>
                <button
                  className="rn-button-style--2 btn-solid"
                  type="submit"
                  value="submit"
                  name="submit"
                  id="mc-embedded-subscribe"
                >
                  Potvrdi
                </button>
              </form>
            </div>
          </div>
          <div className="col-lg-6 order-1 order-lg-2">
            <div className="thumbnail mt--100 mb_md--30 mb_sm--30">
              <img src="/test1.png" alt="trener" />
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        draggable
        pauseOnHover
      />
    </div>
  );
}
export default ContactTwo;
